import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";

import "@/assets/styles/main.scss";
import VueApexCharts from "vue3-apexcharts";

import { VDataTable } from "vuetify/labs/VDataTable";

import "@mdi/font/css/materialdesignicons.css";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
});

createApp(App)
  .use(vuetify)
  .use(store)
  .use(router)
  .component("VDataTable", VDataTable)
  .component("apexchart", VueApexCharts)
  .mount("#app");
