import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
const routes = [
  {
    path: "/",
    redirect: "/single/dashboard",
    component: () => import("@/layout/DefaultComponent.vue"),
    children: [
      {
        path: "/single/dashboard",
        component: () => import("../views/SingleStat.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/mass/dashboard",
        component: () => import("../views/MassStat.vue"),
        meta: { requiresAuth: true },
      },

      {
        path: "/single/received",
        component: () => import("@/views/ReceivedView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/single/print",
        component: () => import("@/views/PrintView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/single/registration",
        component: () => import("@/views/RegistrationView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/single/shipment",
        name: "shipment",
        component: () => import("@/views/ShipmentView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/single/finished",
        component: () => import("@/views/FinishedView.vue"),
        meta: { requiresAuth: true },
      },

      {
        path: "/mass/received",
        component: () => import("@/views/ReceivedView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/mass/print",
        component: () => import("@/views/PrintView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/mass/registration",
        component: () => import("@/views/RegistrationView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/mass/shipment",
        component: () => import("@/views/ShipmentView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/mass/finished",
        component: () => import("@/views/FinishedView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/mass/school-list",
        component: () => import("@/views/SchoolList.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/mass/school-list/:id",
        component: () => import("@/views/SchoolFiles.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/mass/features",
        component: () => import("@/views/FeaturesView.vue"),
        meta: { requiresAuth: true },
      },

      {
        path: "/mass/duplicated",
        component: () => import("@/views/DuplicatedList.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },

  {
    path: "/login",
    component: () => import("@/layout/GeneralComponent.vue"),
    children: [
      {
        path: "",
        name: "login",
        component: () => import("@/components/LoginComponent.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    next();
  }
});

export default router;
