import { createStore } from "vuex";
import axios from "@/axios.js";

export default createStore({
  state: {
    token: localStorage.getItem("token") || null,
    switchModel: localStorage.getItem("switchModel") === "true" || false, // Y // Initial value
    user: null,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem("name", user);
    },
    updateSwitchModel(state) {
      state.switchModel = !state.switchModel;
      localStorage.setItem("switchModel", state.switchModel);
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await axios.post("/api/login", credentials);
        const { token, name } = response.data;
        commit("setToken", token);
        commit("setUser", name);
      } catch (error) {
        throw error;
      }
    },
    async logout({ commit, state }) {
      try {
        await axios.get("/api/logout", {
          headers: {
            "X-Auth-Token": state.token,
          },
        });

        commit("setToken", null);
        commit("setUser", null);
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("switchModel");
      } catch (error) {
        localStorage.removeItem("token");
        location.reload();
        throw error;
      }
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.token !== null;
    },
    activeToken(state) {
      return state.token;
    },
    currentUser(state) {
      return state.user;
    },
  },
});
