// src/axios.js
import axios from "axios";

const apiKey = "https://api.cards.edus.kz/public/";
// const apiKey = "http://192.168.31.88:8080/";

const instance = axios.create({
  baseURL: apiKey, // Your API base URL
});

export default instance;
