<template>
  <router-view />
</template>

<script>
import axios from "@/axios.js";
export default {
  methods: {
    checkToken() {
      const apiUrl = `/api/check/token`;
      const headers = {
        "Content-Type": "application/json",
      };
      const requestBody = {
        token: `${this.$store.getters.activeToken}`,
      };

      axios
        .get(apiUrl, {
          headers: headers,
          params: requestBody,
        })
        .then((response) => {
          console.log("Response data:", response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
          this.logout();
        });
    },
    async logout() {
      try {
        await this.$store.dispatch("logout");
        this.$router.push("/login");
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    // if (!localStorage.getItem("token")) {
    //   console.log("token is empty");
    // } else {
    //   this.checkToken();
    // }
  },
};
</script>

<style></style>
